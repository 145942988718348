import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Brian S. Haney</h1>
        <h2>About Me</h2>
        <a>
          I am a Lawyer and Open Source Software Engineer. I graduated from Washington & Jefferson College in 2015 and from Notre Dame Law School in 2018. In 2020, I completed a research fellowship at Stanford Law School, focusing my work on machine learning patents. My practice focus is business law, technology companies, and legal automation. My software development focus is artificial intelligence and decentralized systems.
        </a>
        <a>
        </a>

        <a>
          ______________
        </a>

        <h2>Links</h2>
        <a href="https://scholar.google.com/citations?user=6_XhYkQAAAAJ&hl=en&oi=ao" style={{ color: 'white' }}>Google Scholar</a>
        <a href="https://github.com/Bhaney44" style={{ color: 'white' }}>GitHub</a>
        <a href="https://papers.ssrn.com/sol3/cf_dev/AbsByAuth.cfm?per_id=3118913" style={{ color: 'white' }}>SSRN</a>
        <a href="https://www.linkedin.com/in/brian-haney-239579301/" style={{ color: 'white' }}>LinkedIn</a>
        
        <h2>Select Publications & Patents</h2>
        <h4>
          Book
        </h4>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3917614" style={{ color: 'white' }}>High Technology Patents (2023).</a>
        <h4>
          Artificial Intelligence
        </h4>
        <a href="https://scholarship.law.nd.edu/cgi/viewcontent.cgi?article=1689&context=jleg" style={{ color: 'white' }}>The Perils and Promises of Artificial General Intelligence, Notre Dame (2018).</a>
        <a href="https://law.stanford.edu/wp-content/uploads/2020/09/haney_wp63.pdf" style={{ color: 'white' }}>Reinforcement Learning Patents: A Transatlantic Review, Stanford (2020).</a>
        <a href="https://scholarship.kentlaw.iit.edu/cgi/viewcontent.cgi?article=1277&context=ckjip" style={{ color: 'white' }}>AI Patents: A Data Drive Approach, Illinois Institute of Technology (2020).</a>
        <a href="https://lira.bc.edu/files/pdf?fileid=3cd42352-0cd5-49f9-8033-0caad2e0dd0c" style={{ color: 'white' }}>Applied Natural Language Processing for Law Practice, Boston College (2020).</a>
        <a href="https://repository.uclawsf.edu/cgi/viewcontent.cgi?article=1093&context=hastings_science_technology_law_journal" style={{ color: 'white' }}>Applied AI in Modern Warfare and National Security Policy, UC Hastings (2020).</a>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3594515" style={{ color: 'white' }}>Pants for NLP Software: An Empirical Review, IUP Journal of Knowledge Management (2020).</a>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3570254" style={{ color: 'white' }}>Deep Reinforcement Learning Patents: An Empirical Survey, UCLA (2020).</a>
        <h4>
          Quantum Computing 
        </h4>
        <a href="https://www.bu.edu/jostl/files/2021/06/2-Haney.pdf" style={{ color: 'white' }}>Quantum Patents, Boston University (2020).</a>
        <a href="https://scholarlycommons.law.case.edu/cgi/viewcontent.cgi?article=1131&context=jolti" style={{ color: 'white' }}>Quantum Machine Learning: A Patent Review, Case Western (2021).</a>
        <h4>
          Blockchain
        </h4>
        <a href="https://scholarship.law.unc.edu/cgi/viewcontent.cgi?article=1501&context=ncbi" style={{ color: 'white' }}>Blockchain: Post-Quantum Security & Legal Economics, University of North Carolina (2020).</a>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3819055" style={{ color: 'white' }}>Algorand Autonomous, MIT (2021).</a>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3880112" style={{ color: 'white' }}>Cryptosecurity: An Analysis of Cryptocurrency Security and Securities, Tulane (2021).</a>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3887719" style={{ color: 'white' }}>Smart Contracts on Algorand, IUP Journal of Knowledge Management  (2021).</a>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3913316" style={{ color: 'white' }}>Decentralized Decisions on Algorand with Choice Coin, SSRN Paper No. 3913316 (2021).</a>
        <h4>
          Compliance
        </h4>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3261360" style={{ color: 'white' }}>Automated Source Selection Scoring & FAR Compliance, American Bar Association (2018).</a>
        <a href="https://tlp.law.pitt.edu/ojs/tlp/article/view/225/220" style={{ color: 'white' }}>Calculating Corporate Compliance & The Foreign Corrupt Practices Act, University of Pittsburgh (2018).</a>
        <a href="https://papers.ssrn.com/sol3/Papers.cfm?abstract_id=4189147" style={{ color: 'white' }}>Statistical Securities Compliance on Solana, University of Florida (2022).</a>
        <h4>
          Aerospace
        </h4>
        <a href="https://patents.google.com/patent/US11613384B2/en" style={{ color: 'white' }}>Precision landing for rockets using deep reinforcement learning, U.S. Patent No. 11,613,384 (2023).</a>
        <a href="https://patents.google.com/patent/US20230249847A1/en?oq=Application+US17%2f669%2c304" style={{ color: 'white' }}>Methods and Device for Autonomous Rocketry, U.S. Patent Publication 17,669,304 (2023).</a>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3437353" style={{ color: 'white' }}>Rocket Patent Strategies, University of San Francisco (2020).</a>
        <h4>
          Health
        </h4>
        <a href="https://patents.google.com/patent/US20220281938A1/en" style={{ color: 'white' }}>Biotechnology for curing hypothyroidism, U.S. Patent Publication 17/194,823 (2021).</a>
        <a href="https://patents.google.com/patent/US20230347050A1/" style={{ color: 'white' }}>Synthetic thyroid methods and apparatus, U.S. Patent Publication 17/734,024 (2022).</a>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4366405" style={{ color: 'white' }}>Inventions and Patents for Treating Thyroid Disease, Indiana University (2023).</a>
        <h4>
          Millenium Prize Problems
        </h4>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3673750" style={{ color: 'white' }}>A Proof for the Birch Swinnerton-Dyer Conjecture (2020).</a>
        <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3801362" style={{ color: 'white' }}>A Proof for Navier-Stokes Smoothness (2021).</a>
        
        <div>
          ___________
        </div>
      </header>
    </div>
  );
}

export default App;
